import React from "react";
import i18n from "../../utils/i18n";

export default class DownloaderStep4Finish extends React.Component {

    gotoDownloaderStart() {
        window.location.href = "/";
    }

    render() {
        return (
            <section id="finished">
                <p>{i18n.t("downloader:finished.desc")}</p>
                <button onClick={this.gotoDownloaderStart.bind(this)}>
                    {i18n.t('downloader:action_download_other.label')}
                </button>
            </section>
        )
    }
}