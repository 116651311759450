import React from "react";
import i18n from "../../utils/i18n"
import {loading} from "../../utils/layout";
import {callAPI} from "../../utils/xhr";

export default class DownloaderStep3Download extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            processed: [],
            zipping: false
        };
    }

    componentDidMount() {
        this.processNextVideo();
    }

    componentDidUpdate(_, prevState) {
        const processedLen = this.state.processed.length;

        if (prevState.processed.length < processedLen) { // a video got processed, which was the reason for the component update -> process the next one
            if (this.props.data.videos[processedLen]) {
                this.processNextVideo();
            } else if (processedLen === this.props.data.videos.length) {
                if (processedLen === 1) {
                    const filePath = this.state.processed[0];
                    const fileName = filePath.split("/").pop();
                    this.triggerDownload(filePath, fileName).then(() => {
                        this.props.onNextStep();
                    });
                } else {
                    this.zipProcessedFiles()
                }
            }
        }
    }

    /**
     * Performs an API call to create a zip file containing all processed videos
     */
    zipProcessedFiles() {
        this.setState({zipping: true});

        callAPI("/api/v1/pack", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({files: this.state.processed})
        })
            .then((resp) => resp.json())
            .then((data) => {
                if (data && data.file) {
                    this.triggerDownload(data.file, "playlist.zip").then(() => {
                        this.props.onNextStep();
                    });
                } else {
                    this.setState({error: true});
                }
            }).catch(() => {
            this.setState({error: true});
        })
    }

    /**
     * Triggers a download for the given file
     *
     * @param file
     * @param newName
     */
    triggerDownload(file, newName) {
        return new Promise((resolve) => {
            callAPI(file, {method: 'GET'})
                .then(response => response.blob())
                .then((blob) => {
                    const blobURL = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = blobURL;
                    a.download = newName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    resolve();
                })
                .catch(() => {
                    this.setState({error: true});
                    resolve();
                });
        })
    }

    /**
     * Takes the next video from the list and performs an API call, which then downloads and converts the video and saves it on the server
     */
    processNextVideo() {
        const video = this.props.data.videos[this.state.processed.length];
        video.prefix = this.getPrefixForNextVideo();

        callAPI("/api/v1/process", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({video: video})
        })
            .then((resp) => resp.json())
            .then((data) => {
                if (data && data.file) {
                    this.setState(prevState => {
                        const processed = [...prevState.processed];
                        processed.push(data.file);
                        return {
                            processed: processed
                        };
                    });
                } else {
                    this.setState({error: true});
                }
            }).catch(() => {
            this.setState({error: true});
        })
    }

    /**
     * Returns the prefix for the next to video, based on the user input and amount of already processed videos
     *
     * @returns {string}
     */
    getPrefixForNextVideo() {
        let prefix = this.props.data.prefix.trim();
        if (prefix.length === 0) {
            return ""
        }

        const numMatch = prefix.match(/%d{(\d)}/);
        if (numMatch && numMatch[1]) { // there is a placeholder (e.g. '%d{2}') -> replace with a digit
            const numStart = +this.props.data.prefixNumStart || 1;
            const num = numStart + this.state.processed.length;
            const numLen = Math.max(+numMatch[1], num.toString().length);
            const formattedNum = num.toString().padStart(numLen, '0');
            prefix = prefix.replace(/%d{\d}/, formattedNum);
        }

        return prefix;
    }

    render() {
        if (this.state.error) {
            return (
                <div className="error">
                    <p>{i18n.t("downloader:xhr_error.download")}</p>
                    <button className="back" onClick={this.props.onPrevStep}>{i18n.t('action_back.label')}</button>
                </div>
            )
        } else {
            const ret = [loading()];
            if (this.state.zipping) { // files are downloaded and now are being zipped
                ret.push(
                    <p key={"loadingDesc"} className={"loadingDesc"}>{i18n.t('downloader:zipping.desc')}</p>
                )
            } else if (this.props.data.videos.length >= 2) { // display progress if there are more than 1 video to be processed
                ret.push(
                    <div key={"progress"} className={"progress"}>
                        <div
                            style={{width: (this.state.processed.length / this.props.data.videos.length * 100) + "%"}}/>
                        <span>{this.state.processed.length} / {this.props.data.videos.length}</span>
                    </div>
                )
            }
            return ret;
        }
    }
}