import React from "react";
import i18n from "../../utils/i18n"
import {loading} from "../../utils/layout";
import {callAPI} from "../../utils/xhr";
import InfoModal from "../modal/infoModal";
import CoverArtModal from "../modal/coverArtModal";
import {getCoverArtSuggestions} from "../../utils/coverArt";

export default class DownloaderStep2Video extends React.Component {

    constructor(props) {
        super(props);

        this.fieldPrefix = React.createRef();
        this.fieldArtist = React.createRef();
        this.fieldTitle = React.createRef();

        this.state = {
            info: null
        };
    }

    componentDidMount() {
        callAPI("/api/v1/video/details/" + this.props.data.video)
            .then((resp) => resp.json())
            .then((data) => {
                if (data && data.info && data.info.id) {
                    this.setState({info: data.info}, async () => {
                        await this.loadCoverArtSuggestions(data.info.title.artist, data.info.title.track);
                    });
                } else {
                    this.setState({error: true});
                }
            }).catch((err) => {
            console.error(err)
            this.setState({error: true});
        })
    }

    handleDownload(e) {
        this.props.onNextStep({
            videos: [
                {
                    id: e.target.getAttribute("data-id"),
                    artist: this.fieldArtist.current.value,
                    track: this.fieldTitle.current.value,
                    cover: this.state.info ? this.state.info.cover : null
                }
            ],
            prefix: this.fieldPrefix.current.value
        });
    }

    async loadCoverArtSuggestions(artist, track) {
        const data = await getCoverArtSuggestions(artist, track);
        if (data.error) {
            this.setState({message: i18n.t("downloader:xhr_error.cover_art")});
        } else {
            let selectedCover = null;
            if (data.covers[0] && data.covers[0].url) {
                selectedCover = data.covers[0].url;
            }

            this.setState(prevState => {
                const newInfo = {...prevState.info};
                newInfo.cover = selectedCover;
                newInfo.coverSuggestions = data.covers;
                return {
                    info: newInfo
                };
            });
        }
    }

    dismissMessage() {
        this.setState({message: null});
    }

    dismissCoverArtModal() {
        this.setState({coverArtModal: false});
    }

    setCoverArt(url) {
        this.setState(prevState => {
            const newInfo = {...prevState.info};
            newInfo.cover = url;
            return {
                info: newInfo
            };
        });
    }

    showCoverArtModal() {
        this.setState({
            coverArtModal: {
                suggestions: this.state.info.coverSuggestions,
                cover: this.state.info.cover,
                thumbnail: this.state.info.thumbnail,
                artist: this.fieldArtist.current.value,
                track: this.fieldTitle.current.value
            }
        })
    }

    /**
     *
     * @returns {[]}
     */
    render() {
        const ret = [];

        if (this.state.error) {
            ret.push(
                <div key={"error"} className="error">
                    <p>{i18n.t("downloader:xhr_error.info")}</p>
                    <button className="back" onClick={this.props.onPrevStep}>{i18n.t('action_back.label')}</button>
                </div>
            )
        } else if (this.state.info) {
            const coverLoading = typeof this.state.info.coverSuggestions === "undefined";

            ret.push(
                <section key={"details"} id="details">
                    <div className="videoDetails">
                        <strong>
                            <a href={this.props.ytBaseUrl + this.state.info.id} target="_blank"
                               rel="noopener noreferrer">
                                {this.state.info.title.titleOrig}
                            </a>
                        </strong>
                        <div className="cover">
                            {coverLoading ? loading() : ""}
                            <span><img loading="lazy" src={this.state.info.cover || ""}
                                       alt={this.state.info.title.titleFull}/></span>
                            <button className="cover" onClick={this.showCoverArtModal.bind(this)}>
                                {i18n.t('downloader:action_edit.label')}
                            </button>
                        </div>

                        <form autoComplete="off">
                            <div>
                                <label htmlFor="prefix">{i18n.t("downloader:field_prefix.label")}</label>
                                <input type="text" ref={this.fieldPrefix} id="prefix"/>
                            </div>
                            <div>
                                <label htmlFor="artist">{i18n.t("downloader:field_artist.label")}</label>
                                <input type="text"
                                       ref={this.fieldArtist}
                                       id="artist"
                                       defaultValue={this.state.info.title.artist}/>
                            </div>
                            <div>
                                <label htmlFor="title">{i18n.t("downloader:field_track.label")}</label>
                                <input type="text"
                                       ref={this.fieldTitle}
                                       id="title"
                                       defaultValue={this.state.info.title.track}/>
                            </div>
                        </form>
                    </div>

                    <button className="back" onClick={this.props.onPrevStep}>{i18n.t('action_back.label')}</button>

                    <button className={"download"} onClick={this.handleDownload.bind(this)}
                            data-id={this.state.info.id}>
                        {i18n.t('downloader:action_download.label')}
                    </button>
                </section>
            )
        } else {
            ret.push(loading())
        }

        if (this.state.message) {
            ret.push(<InfoModal key="infoModal" unmount={this.dismissMessage.bind(this)} content={this.state.message}/>)
        }

        if (this.state.coverArtModal) {
            ret.push(<CoverArtModal key="coverArtModal"
                                    unmount={this.dismissCoverArtModal.bind(this)}
                                    update={this.setCoverArt.bind(this)}
                                    suggestions={this.state.coverArtModal.suggestions}
                                    thumbnail={this.state.coverArtModal.thumbnail}
                                    cover={this.state.coverArtModal.cover}
                                    artist={this.state.coverArtModal.artist}
                                    track={this.state.coverArtModal.track}/>)
        }

        return ret;
    }
}