import React, {useEffect} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import i18n from "../../utils/i18n"

import {getFooter, getHeader, loading} from "../../utils/layout"
import Downloader from "../downloader/downloader";
import NotFound from "../notFound/notFound";
import {hasAuthParams, useAuth} from "react-oidc-context";

function App() {
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = React.useState(false);

    useEffect(() => {
        window.document.documentElement.lang = i18n.language;
    }, []);

    // automatically sign-in
    React.useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin
        ) {
            auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    let content;
    if (auth.isLoading) {
        content = loading();
    } else if (!auth.isAuthenticated) {
        content = (<div className="error">
            <p>{i18n.t("auth.error")}</p>
        </div>);
    } else {
        content = (<BrowserRouter>
            <Routes>
                <Route exact path='/' element={<Downloader/>}/>
                <Route exact path='*' element={<NotFound/>}/>
            </Routes>
        </BrowserRouter>);
    }

    return [
        getHeader(),
        <main key="main">{content}</main>,
        getFooter()
    ];
}

export default App;
