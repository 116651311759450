import React from "react";
import i18n from "../../utils/i18n";
import {getCoverArtSuggestions} from "../../utils/coverArt";
import {loading} from "../../utils/layout";

export default class CoverArtModal extends React.Component {
    ALLOWED_TYPES = ["image/jpeg", "image/png"];

    constructor(props) {
        super(props);

        this.state = {
            searchResults: null,
            searchValue: null,
            searchRunning: false,
            className: null,
            urlError: null,
            selectedCover: null,
            activeTab: "suggestions"
        };
    }

    componentDidMount() {
        document.body.classList.add("modal-visible");

        setTimeout(() => {
            this.setState({className: "visible"});
        }, 0);
    }

    componentWillUnmount() {
        document.body.classList.remove("modal-visible");

        if (this.props.unmount) {
            this.props.unmount()
        }
    }

    updateCover(url) {
        this.setState({selectedCover: url});
        this.props.update(url);
    }

    async handleFileUpload(e) {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {
                this.updateCover(reader.result)
            };
        }
    }

    async handleManualURL(e) {
        let url = (e.target.value || "").trim();

        if (url.length > 0) {
            try {
                const resp = await fetch(url);

                if (resp.status === 404) {
                    this.setState({urlError: i18n.t("downloader:cover_art.url_error.status_code_404")})
                    url = "";
                } else if (resp.status >= 400) {
                    this.setState({urlError: i18n.t("downloader:cover_art.url_error.status_code_other")})
                    url = "";
                } else {
                    const contentType = (resp.headers.get("content-type") || "").split(";")[0];
                    if (this.ALLOWED_TYPES.indexOf(contentType) === -1) {
                        this.setState({urlError: i18n.t("downloader:cover_art.url_error.file_type")})
                        url = "";
                    } else {
                        this.setState({urlError: null})
                    }
                }
            } catch (err) {
                console.error(err);
                this.setState({urlError: i18n.t("downloader:cover_art.url_error.unknown")});
                url = "";
            }
        }

        this.updateCover(url)
    }

    changeTab(tabName) {
        this.setState({
            activeTab: tabName
        });
    }

    searchCoverArt(e) {
        const searchValue = (e.target.value || "").trim();

        if ((e.type === "blur" || e.key === 'Enter') && !this.state.searchRunning && searchValue.length > 0 && this.state.searchValue !== searchValue) {
            this.setState({
                searchValue: searchValue,
                searchRunning: true,
            }, async () => {
                let searchValueSplitted = searchValue.split(" - ");
                if (searchValueSplitted.length <= 1) { // no " - " in the search value -> split at a whitespace instead
                    searchValueSplitted = searchValue.split(" ");
                }
                const artist = searchValueSplitted.shift();
                const track = searchValueSplitted.join(" ");

                const data = await getCoverArtSuggestions(artist, track);
                this.setState({
                    searchResults: data.error ? null : data.covers,
                    searchRunning: false
                });
            });
        }
    }

    dismiss(e) {
        e.preventDefault();
        this.setState({className: null});

        setTimeout(() => {
            this.componentWillUnmount()
        }, 100);
    }

    renderSuggestions() {
        if (this.state.searchRunning) {
            return loading();
        }

        const entries = [];
        const suggestions = this.state.searchResults || this.props.suggestions;

        if (suggestions) {
            for (const suggestion of suggestions) {
                entries.push(
                    <li key={"suggestion_" + suggestion.id}
                        onClick={this.updateCover.bind(this, suggestion.url)}
                        data-source={suggestion.source}>
                        <span className="cover"><img src={suggestion.url} alt={suggestion.track}/></span>
                        <div className="info">
                            <span className="artist">{suggestion.artist.join(", ")}</span>
                            <span className="track">{suggestion.track}</span>
                        </div>
                    </li>
                );
            }
        }

        if (this.props.thumbnail) {
            entries.push(
                <li key={"suggestion_thumbnail"}
                    onClick={this.updateCover.bind(this, this.props.thumbnail)}
                    data-source={"thumbnail"}>
                    <span className="cover"><img src={this.props.thumbnail} alt={"Thumbnail"}/></span>
                    <div className="info">
                        <span className="artist">{i18n.t("downloader:cover_art.thumbnail")}</span>
                        <span className="track">{this.props.artist} - {this.props.track}</span>
                    </div>
                </li>
            );
        }

        if (entries.length > 0) {
            return <ul className="suggestions">{entries}</ul>;
        } else {
            return <div className="suggestion-empty">{i18n.t("downloader:cover_art.suggestions_empty")}</div>;
        }
    }

    renderManual() {
        return (
            <div className="manual">
                <div>
                    <label htmlFor="url">{i18n.t("downloader:cover_art.url")}</label>
                    <input type="text" onInput={this.handleManualURL.bind(this)} id="url"/>
                    {this.state.urlError ? <span className="error">{this.state.urlError}</span> : ""}
                </div>
                <span>{i18n.t("downloader:cover_art.manual_choice")}</span>
                <div>
                    <label htmlFor="upload">{i18n.t("downloader:cover_art.upload")}</label>
                    <input type="file"
                           accept={this.ALLOWED_TYPES.join(",")}
                           onChange={this.handleFileUpload.bind(this)}
                           id="upload"/>
                </div>
            </div>
        )
    }

    render() {
        return (
            <section id="modal" data-name="coverArt" className={this.state.className}>
                <header>
                    <span className="cover">
                        <img src={this.state.selectedCover || this.props.cover || ""}
                             alt={this.props.track}/>
                    </span>
                    <strong>{this.props.artist} - {this.props.track}</strong>
                    <menu>
                        <li className={this.state.activeTab === "suggestions" ? "active" : ""}
                            onClick={this.changeTab.bind(this, "suggestions")}>
                            {i18n.t("downloader:cover_art.suggestions")}
                        </li>
                        <li className={this.state.activeTab === "manual" ? "active" : ""}
                            onClick={this.changeTab.bind(this, "manual")}>
                            {i18n.t("downloader:cover_art.manual")}
                        </li>
                        {this.state.activeTab === "suggestions" ?
                            <li className="search">
                                <input type="text" onKeyUp={this.searchCoverArt.bind(this)}
                                       onBlur={this.searchCoverArt.bind(this)} id="searchInput"/>
                            </li> : ""}
                    </menu>
                </header>
                {this.state.activeTab === "suggestions" ? this.renderSuggestions() : ""}
                {this.state.activeTab === "manual" ? this.renderManual() : ""}
                <button className="dismiss" onClick={this.dismiss.bind(this)}>
                    {i18n.t("downloader:modal.close")}
                </button>
            </section>
        );
    }
}