import React from "react";
import i18n from "../../utils/i18n";
import {Link, Navigate} from "react-router-dom";

export default class NotFound extends React.Component {

    componentDidMount() {
        document.title = i18n.t("page_404.title");
    }

    isRedirectUrl() {
        return window.location.pathname.startsWith("/cdn-cgi/");
    }

    render() {
        if (this.isRedirectUrl()) {
            return <Navigate to="/"/>
        } else {
            return (
                <section id="notFound">
                    <p>{i18n.t("page_404.desc")}</p>
                    <Link to="/" className="back">{i18n.t('action_back.desc')}</Link>
                </section>
            );
        }
    }
}