import React from "react";
import i18n from "./i18n";
import packageJson from "../../package.json";

function getCopyrightDate() {
    const now = new Date();

    if (now.getFullYear() > packageJson.copyright_year) {
        return packageJson.copyright_year + " - " + now.getFullYear();
    }

    return packageJson.copyright_year;
}

export function loading() {
    const bars = [];

    for (let i = 0; i < 14; i++) {
        bars.push(<i key={i}/>);
    }

    return <div key="loading" className='loading'>{bars}</div>
}

export function getHeader() {
    return (
        <header key="header">
            <div>
                <a href={"/"} id="logo">{i18n.t('action_back.desc')}</a>
            </div>
        </header>
    )
}

export function getFooter() {
    return (
        <footer key="footer">
            <div>
                <span className="copy">© {getCopyrightDate()} <strong>{i18n.t('app.author')}</strong></span>
                <nav>
                    <a href="https://philipp-koenig.com/contact" target="_blank"
                       rel="noopener noreferrer">{i18n.t('page_contact.title')}</a>
                    <a href="https://philipp-koenig.com/privacy" target="_blank"
                       rel="noopener noreferrer">{i18n.t('page_privacy.title')}</a>
                </nav>
            </div>
        </footer>
    )
}