import React from "react";
import i18n from "../../utils/i18n";

export default class InfoModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            className: null
        };
    }

    componentDidMount() {
        document.body.classList.add("modal-visible");

        setTimeout(() => {
            this.setState({className: "visible"});
        }, 0);
    }

    componentWillUnmount() {
        document.body.classList.remove("modal-visible");

        if (this.props.unmount) {
            this.props.unmount()
        }
    }

    dismiss(e) {
        e.preventDefault();
        this.setState({className: null});

        setTimeout(() => {
            this.componentWillUnmount()
        }, 100);
    }

    render() {
        return (
            <section id="modal" data-name="info" className={this.state.className}>
                <p>{this.props.content}</p>
                <button className="dismiss" onClick={this.dismiss.bind(this)}>
                    {i18n.t("downloader:modal.close")}
                </button>
            </section>
        );
    }
}