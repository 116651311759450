import React from "react";
import i18n from "../../utils/i18n"
import InfoModal from "../modal/infoModal";

export default class DownloaderStep1 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            message: null
        };
    }

    handleSubmit(e) {
        e.preventDefault();

        const data = new FormData(e.target);
        const urlStr = data.get("q");

        if (urlStr.length === 0) { // empty input
            this.setState({message: i18n.t("downloader:input_field.error_empty")});
            return;
        }

        try {
            const url = new URL(urlStr);

            if (!this.isValidUrl(url)) { // url is missing required parts
                this.setState({message: i18n.t("downloader:input_field.error_invalid")});
                return;
            }

            this.props.onNextStep({
                video: url.searchParams.get("v"),
                playlist: url.searchParams.get("list")
            });
        } catch (e) {
            this.setState({message: i18n.t("downloader:input_field.error_invalid")});
        }
    }

    isValidUrl(url) {
        return (
            (url.host === "www.youtube.com" || url.host === "youtube.com") &&
            (url.searchParams.has("v") || url.searchParams.has("list"))
        );
    }

    dismissMessage() {
        this.setState({message: null});
    }

    render() {
        const ret = [
            <section id="search" key="search">
                <p>{i18n.t("downloader:input_field.desc")}</p>
                <form onSubmit={this.handleSubmit.bind(this)} autoComplete="off">
                    <input type="text" name="q"/>
                    <button type="submit"/>
                </form>
            </section>
        ];

        if (this.state.message) {
            ret.push(<InfoModal key="msg" unmount={this.dismissMessage.bind(this)} content={this.state.message}/>)
        }

        return ret;
    }
}