import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import common_en from "../translations/en/common.json";
import downloader_en from "../translations/en/downloader.json";

import common_de from "../translations/de/common.json";
import downloader_de from "../translations/de/downloader.json";

const fallbackLng = ['en'];
const availableLanguages = ['en', 'de'];

i18n
    .use(LanguageDetector) // detect user language
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        interpolation: {escapeValue: false}, // React already does escaping
        fallbackLng: fallbackLng,
        supportedLngs: availableLanguages,
        defaultNS: "common",
        debug: false,
        resources: {
            en: {
                common: common_en,
                downloader: downloader_en
            },
            de: {
                common: common_de,
                downloader: downloader_de
            }
        }
    });

export default i18n;