import {callAPI} from "./xhr";

export async function getCoverArtSuggestions(artist, track) {
    try {
        const response = await callAPI("/api/v1/coverArt", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                artist: artist,
                track: track
            })
        });
        const json = await response.json();

        if (json && json.covers) {
            return {covers: json.covers};
        } else {
            return {error: "data"};
        }
    } catch (err) {
        console.error(err);
        return {error: "xhr"};
    }
}