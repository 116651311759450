import React from 'react';
import {createRoot} from 'react-dom/client';
import './assets/css/style.css';
import App from './components/app/app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import "./utils/i18n"
import {AuthProvider} from "react-oidc-context";
import {WebStorageStateStore} from "oidc-client-ts";

const oidcConfig = {
    authority: process.env.REACT_APP_OAUTH_AUTHORITY,
    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
    client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
    redirect_uri: process.env.REACT_APP_OAUTH_REDIRECT_URI,
    userStore: new WebStorageStateStore({store: window.localStorage}),
};

const onSigninCallback = () => {
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname
    )
}

const root = createRoot(document.querySelector('div#app'));

root.render(
    <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
        <App/>
    </AuthProvider>
);

serviceWorkerRegistration.register();