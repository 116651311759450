import {User} from "oidc-client-ts";

/**
 * Performs an API call by calling the fetch method with the matching api base URL
 *
 * @param path
 * @param opts
 * @returns {Promise<Response>}
 */
export async function callAPI(path, opts = {}) {
    const accessToken = getAccessToken();

    if (accessToken) {
        if (!opts.headers) {
            opts.headers = {};
        }
        opts.headers['Authorization'] = 'Bearer ' + accessToken;
    }

    const response = await fetch(process.env.REACT_APP_BACKEND_BASE_URI + path, opts);
    if (response.status === 401) {
        clearAccessToken();
        window.location.reload();
    }
    return response;
}

function getAccessToken() {
    const oidcStorage = localStorage.getItem(`oidc.user:${process.env.REACT_APP_OAUTH_AUTHORITY}:${process.env.REACT_APP_OAUTH_CLIENT_ID}`);
    if (!oidcStorage) {
        return null;
    }
    return User.fromStorageString(oidcStorage).access_token;
}

function clearAccessToken() {
    localStorage.removeItem(`oidc.user:${process.env.REACT_APP_OAUTH_AUTHORITY}:${process.env.REACT_APP_OAUTH_CLIENT_ID}`);
}