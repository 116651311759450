import React from "react";
import i18n from "../../utils/i18n"
import {loading} from "../../utils/layout";
import {callAPI} from "../../utils/xhr";
import InfoModal from "../modal/infoModal";
import CoverArtModal from "../modal/coverArtModal";
import {getCoverArtSuggestions} from "../../utils/coverArt";

export default class DownloaderStep2Playlist extends React.Component {

    constructor(props) {
        super(props);

        this.videoListElm = React.createRef();
        this.prefixField = React.createRef();
        this.prefixNumStartField = React.createRef();
        this.mounted = false;

        this.state = {
            loading: false,
            allLoaded: false,
            checked: {},
            contextmenu: {},
            info: null,
            nextPageToken: null,
            videos: []
        };
    }

    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling.bind(this));
        document.addEventListener('click', this.hideContextmenu.bind(this));
        this.mounted = true;

        callAPI("/api/v1/playlist/details/" + this.props.data.playlist)
            .then((resp) => resp.json())
            .then((data) => {
                if (data && data.info) {
                    this.setState({info: data.info});
                } else {
                    this.setState({error: true});
                }
            }).catch(() => {
            this.setState({error: true});
        })
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling.bind(this));
        document.removeEventListener('click', this.hideContextmenu.bind(this));
        this.mounted = false;
    }

    componentDidUpdate() {
        if (this.state.videos.length === 0) {
            this.trackScrolling();
        }
    }

    showContextmenu(e) {
        e.preventDefault();
        this.setState({contextmenu: {visible: true, x: e.clientX, y: e.clientY, target: e.target}});
    }

    hideContextmenu() {
        if (this.state.contextmenu && this.state.contextmenu.visible) {
            this.setState({contextmenu: {visible: false}})
        }
    }

    async trackScrolling() {
        this.hideContextmenu();

        if (this.state.loading || this.state.allLoaded) {
            return;
        }

        if (this.videoListElm.current && this.videoListElm.current.getBoundingClientRect().bottom - 800 < window.innerHeight) {
            this.setState({loading: true});

            const formData = new FormData();
            if (this.state.nextPageToken) {
                formData.append("nextPageToken", this.state.nextPageToken);
            }

            try {
                const response = await callAPI("/api/v1/playlist/videos/" + this.props.data.playlist, {
                    method: 'POST',
                    body: formData
                });
                const json = await response.json();

                if (json && json.info) {
                    this.setState(prevState => {
                        return {
                            nextPageToken: json.info.nextPageToken,
                            allLoaded: json.info.nextPageToken.length === 0,
                            videos: [...prevState.videos, ...json.info.videos],
                            loading: false
                        };
                    }, async () => {
                        let i = 0;
                        for (const video of this.state.videos) {
                            if (typeof video.coverSuggestions === "undefined") {
                                await this.loadCoverArtSuggestions(video.title.artist, video.title.track, i);
                            }
                            i++;

                            if (!this.mounted) {
                                break;
                            }
                        }
                    });
                } else {
                    this.setState({error: true, loading: false});
                }
            } catch (err) {
                console.error(err);
                this.setState({error: true, loading: false});
            }
        }
    }

    handleDownload() {
        const checkedVideos = this.getCheckedVideos();
        checkedVideos.sort((a, b) => { // sort video ids by number to have them in the order of appearance (rather in the order they were checked by the user)
            const aNum = +a.replace(/[^\d]/g, "");
            const bNum = +b.replace(/[^\d]/g, "");
            return bNum - aNum;
        });

        const resp = [];
        checkedVideos.forEach((k) => {
            const id = document.getElementById(k).getAttribute("data-id");
            const artist = document.getElementById(k + "_artist").value;
            const track = document.getElementById(k + "_track").value;
            const cover = document.getElementById(k + "_cover").value;
            if (id) {
                resp.push({
                    id: id,
                    artist: artist,
                    track: track,
                    cover: cover
                })
            }
        });

        this.props.onNextStep({
            videos: resp,
            prefix: this.prefixField.current.value || "",
            prefixNumStart: this.prefixNumStartField.current.value || 1
        });
    }

    /**
     * Increase/Decrease the counter when a checkbox gets changed
     *
     * @param e
     */
    handleCheckbox(e) {
        const uid = e.target.getAttribute("id");
        const isChecked = e.target.checked;

        this.setState(prevState => {
            const checked = Object.assign({}, prevState.checked);
            checked[uid] = isChecked;
            return {checked};
        });
    }

    /**
     * Returns the IDs of all checked videos
     *
     * @returns {[]}
     */
    getCheckedVideos() {
        const ret = [];
        Object.entries(this.state.checked).forEach(([id, checked]) => {
            if (checked) {
                ret.push(id);
            }
        });
        return ret;
    }

    /**
     * Enables the checkboxes of all visible videos
     */
    checkAllVisible() {
        const checkboxes = document.querySelectorAll("section#playlist > form input[type='checkbox']");
        for (const checkbox of checkboxes) {
            checkbox.checked = false;
            checkbox.click()
        }
    }

    /**
     * Enables all checkboxes until the video, the contextmenu got opened to
     */
    checkAllUntil() {
        if (this.state.contextmenu && this.state.contextmenu.target) {
            const checkboxes = document.querySelectorAll("section#playlist > form input[type='checkbox']");
            for (const checkbox of checkboxes) {
                checkbox.checked = false;
                checkbox.click();

                if (checkbox === this.state.contextmenu.target) {
                    break;
                }
            }
        }
    }

    /**
     * Disables all visible checkboxes
     */
    uncheckAll() {
        const checkboxes = document.querySelectorAll("section#playlist > form input[type='checkbox']");
        for (const checkbox of checkboxes) {
            checkbox.checked = true;
            checkbox.click()
        }
    }

    async loadCoverArtSuggestions(artist, track, i) {
        const data = await getCoverArtSuggestions(artist, track);
        if (data.error) {
            this.setState({message: i18n.t("downloader:xhr_error.cover_art")});
        } else {
            let selectedCover = null;
            if (data.covers[0] && data.covers[0].url) {
                selectedCover = data.covers[0].url;
            }

            this.setState(prevState => {
                const newVideos = [...prevState.videos];
                newVideos[i].cover = selectedCover;
                newVideos[i].coverSuggestions = data.covers;
                return {
                    videos: newVideos
                };
            });
        }
    }

    showCoverArtModal(i) {
        const video = this.state.videos[i];

        this.setState({
            coverArtModal: {
                index: i,
                suggestions: video.coverSuggestions,
                cover: video.cover,
                thumbnail: video.thumbnail,
                artist: document.getElementById("video_" + i + "_artist").value,
                track: document.getElementById("video_" + i + "_track").value
            }
        })
    }

    dismissCoverArtModal() {
        this.setState({coverArtModal: null});
    }

    setCoverArt(i, url) {
        this.setState(prevState => {
            const newVideos = [...prevState.videos];
            newVideos[i].cover = url;
            return {
                videos: newVideos
            };
        });
    }

    dismissMessage() {
        this.setState({message: null});
    }

    /**
     *
     * @returns {[]}
     */
    render() {
        const ret = [];

        if (this.state.error) {
            ret.push(
                <div key={"error"} className="error">
                    <p>{i18n.t("downloader:xhr_error.info")}</p>
                    <button className="back" onClick={this.props.onPrevStep}>{i18n.t('action_back.label')}</button>
                </div>
            )
        } else if (this.state.info) {
            const videoList = [];
            this.state.videos.forEach((video, i) => {
                const coverLoading = typeof video.coverSuggestions === "undefined";

                videoList.push(
                    <li key={"video_" + i}>
                        <input type="checkbox" id={"video_" + i} data-id={video.id}
                               onClick={this.handleCheckbox.bind(this)}
                               onContextMenu={this.showContextmenu.bind(this)}/>
                        <div>
                            <strong>
                                <a href={this.props.ytBaseUrl + video.id} target="_blank" rel="noopener noreferrer">
                                    {video.title.titleOrig}
                                </a>
                            </strong>
                            <div className="cover">
                                {coverLoading ? loading() : ""}
                                <span><img loading="lazy" src={video.cover || ""} alt={video.title.titleFull}/></span>
                                <button className="cover" type="button"
                                        onClick={this.showCoverArtModal.bind(this, i)}>
                                    {i18n.t('downloader:action_edit.label')}
                                </button>
                            </div>
                            <div className="details">
                                <input type="text"
                                       id={"video_" + i + "_artist"}
                                       defaultValue={video.title.artist}
                                       placeholder={i18n.t("downloader:field_artist.label")}/>
                                <input type="text"
                                       id={"video_" + i + "_track"}
                                       defaultValue={video.title.track}
                                       placeholder={i18n.t("downloader:field_track.label")}/>
                                <input type="hidden"
                                       id={"video_" + i + "_cover"}
                                       value={video.cover || ""}/>
                            </div>
                        </div>
                    </li>
                );
            });

            const checkedAmount = this.getCheckedVideos().length;

            ret.push(
                <section key="playlist" id="playlist">
                    <header>
                        <strong>{this.state.info.title}</strong>
                        <img src={this.state.info.thumbnail} alt={this.state.info.title}/>
                        <div>
                            <button className="back" onClick={this.props.onPrevStep}>
                                {i18n.t('action_back.label')}
                            </button>
                            <button className={"download" + (checkedAmount === 0 ? " disabled" : "")}
                                    onClick={this.handleDownload.bind(this)}>
                                {i18n.t('downloader:action_download.label')}
                                <span>[{checkedAmount}]</span>
                            </button>

                            <div className={"prefix"}>
                                <div>
                                    <label htmlFor={"prefix"}>{i18n.t('downloader:field_prefix.label')}</label>
                                    <input type={"text"} id={"prefix"} ref={this.prefixField} defaultValue={"%d{4}_"}/>
                                </div>
                                <div>
                                    <label htmlFor={"prefixNumStart"}>
                                        {i18n.t('downloader:field_prefixNumStart.label')}
                                    </label>
                                    <input type={"number"}
                                           id={"prefixNumStart"}
                                           ref={this.prefixNumStartField}
                                           defaultValue={"1"}/>
                                </div>
                            </div>
                        </div>
                    </header>
                    <form autoComplete="off">
                        <ul ref={this.videoListElm}>{videoList}</ul>
                        {this.state.loading ? loading() : ""}
                    </form>
                </section>
            )
        } else {
            ret.push(loading())
        }

        if (this.state.contextmenu && this.state.contextmenu.visible) {
            ret.push(
                <div key={"contextmenu"} className={"contextmenu"}
                     style={{top: this.state.contextmenu.y + "px", left: this.state.contextmenu.x + "px"}}>
                    <menu>
                        <li>
                            <button className={"checkUntil"} onClick={this.checkAllUntil.bind(this)}>
                                {i18n.t("downloader:contextmenu.checkUntil")}
                            </button>
                        </li>
                        <li>
                            <button className={"checkAll"} onClick={this.checkAllVisible.bind(this)}>
                                {i18n.t("downloader:contextmenu.checkAll")}
                            </button>
                        </li>
                        <li>
                            <button className={"uncheckAll"} onClick={this.uncheckAll.bind(this)}>
                                {i18n.t("downloader:contextmenu.uncheckAll")}
                            </button>
                        </li>
                    </menu>
                </div>
            )
        }

        if (this.state.message) {
            ret.push(<InfoModal key="msg" unmount={this.dismissMessage.bind(this)} content={this.state.message}/>)
        }

        if (this.state.coverArtModal) {
            ret.push(<CoverArtModal key="coverArtModal"
                                    unmount={this.dismissCoverArtModal.bind(this)}
                                    update={this.setCoverArt.bind(this, this.state.coverArtModal.index)}
                                    suggestions={this.state.coverArtModal.suggestions}
                                    thumbnail={this.state.coverArtModal.thumbnail}
                                    cover={this.state.coverArtModal.cover}
                                    artist={this.state.coverArtModal.artist}
                                    track={this.state.coverArtModal.track}/>)
        }

        return ret;
    }
}