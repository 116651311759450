import React from "react";
import i18n from "../../utils/i18n"
import DownloaderStep1 from "./1_index";
import DownloaderStep2Video from "./2_videoDetails";
import {Navigate} from "react-router-dom";
import DownloaderStep2Playlist from "./2_playlistDetails";
import DownloaderStep3Download from "./3_download";
import DownloaderStep4Finish from "./4_finish";

export default class Downloader extends React.Component {

    /**
     * Base URL to the youtube videos
     *
     * @type {string}
     */
    static YT_BASE_URL = "https://www.youtube.com/watch?v=";

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            data: {},
            prevStepData: {}
        };
    }

    componentDidMount() {
        document.title = i18n.t("page_index.title");
    }

    gotoNextStep(data = {}) {
        this.setState(prevState => {
            return {
                step: prevState.step + 1,
                data: data,
                prevStepData: prevState.data
            };
        });
    }

    gotoPrevStep() {
        this.setState(prevState => {
            return {
                step: prevState.step - 1,
                data: prevState.prevStepData,
                prevStepData: {}
            };
        });
    }

    render() {
        const attrList = {
            ytBaseUrl: Downloader.YT_BASE_URL,
            data: this.state.data,
            onNextStep: this.gotoNextStep.bind(this),
            onPrevStep: this.gotoPrevStep.bind(this)
        };

        if (this.state.step === 1) {
            return <DownloaderStep1 key={"downloaderStep1"} {...attrList}/>;
        } else if (this.state.step === 2) {

            if (this.state.data.video) {
                return <DownloaderStep2Video key={"downloaderStep2"} {...attrList}/>;
            } else {
                return <DownloaderStep2Playlist key={"downloaderStep2"} {...attrList}/>;
            }
        } else if (this.state.step === 3 && this.state.data.videos) {
            return <DownloaderStep3Download key={"downloaderStep3"} {...attrList}/>;
        } else if (this.state.step === 4) {
            return <DownloaderStep4Finish key={"downloaderStep4"} {...attrList}/>;
        } else {
            return <Navigate to='/404'/>
        }
    }
}